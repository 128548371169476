<template>
  <div class="edit-currency-wrapper">
    <el-card shadow="never" :body-style="{ padding: '40px' }">
      <h1>通貨編集</h1>
      <editable-table
        :info="info"
        :loading="loading"
        @editName="(name) => $emit('editName', name)"
        @editDescription="(desc) => $emit('editDescription', desc)"
        @editUnit="(unit) => $emit('editUnit', unit)"
      />
    </el-card>
  </div>
</template>

<script>
import EditCurrencyPage from './EditCurrencyPage';
import CurrencyInfo from './CurrencyInfo';
import EditableTable from './EditableTable.vue';

export default {
  name: 'edit-currency',
  components: {
    EditCurrencyPage,
    CurrencyInfo,
    EditableTable,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    info: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped>
.edit-currency-card-wrapper {
  margin-bottom: 50px;
}
h1 {
  font-size: 2rem;
  font-weight: normal;
}
</style>
