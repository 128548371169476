<template>
  <div class="currency-info-wrapper">
    <p id="currency-info-title">通貨情報</p>
    <el-table :data="tableData">
      <el-table-column prop="key" label="項目" />
      <el-table-column prop="value" label="情報" />
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'currency-info',
  props: {
    info: {
      type: Object,
      required: false,
    },
  },
  computed: {
    tableData() {
      return Object.entries(this.info)
        .filter(([key, val]) => key !== 'status')
        .map(([key, val]) => ({
          key: this.convertKey(key),
          value: val,
        }));
    },
  },
  methods: {
    convertKey(key) {
      switch (key) {
        case 'name':
          return '通貨名';
        case 'description':
          return '通貨説明';
        case 'mail':
          return '登録メールアドレス';
        case 'region':
          return '地域';
        case 'unit':
          return '通貨単位';
        default:
          return key;
      }
    },
  },
};
</script>

<style scoped>
.currency-info-wrapper {
  padding: 0px;
}

#currency-info-title {
  font-size: 2rem;
}
</style>
