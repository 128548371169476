<template>
  <div class="input-form-wrapper">
    <span class="input-title-wrapper">{{ index }}</span>
    <el-input
      v-loading="loading"
      :value="value"
      @input="e => onInput(e)"
      class="input-wrapper"
      :disabled="!isEditing"
    />
    <div v-if="!isEditing" class="buttons-wrapper">
      <el-button @click="isEditing = true">編集</el-button>
    </div>
    <div v-else class="buttons-wrapper">
      <el-button @click="onCancel">キャンセル</el-button>
      <el-button type="primary" @click="onSubmit">決定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'input-form',
  data() {
    return {
      isEditing: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    onSubmit() {
      this.isEditing = false;
      this.$emit('click');
    },
    onCancel() {
      this.isEditing = false;
      this.$emit('cancel');
    },
    onInput(e) {
      this.$emit('onInput', e);
    },
  },
};
</script>

<style scoped>
.input-form-wrapper {
  display: flex;
  justify-content: space-between;
}
.input-form-wrapper > * {
  padding: 0 5px;
}
.input-title-wrapper {
  display: flex;
  align-items: center;
  font-size: 1rem;
  width: 240px;
  flex-shrink: 2;
}
.input-wrapper {
  flex-shrink: 2;
}
.buttons-wrapper {
  width: 360px;
  flex-shrink: 1;
}
</style>
