<template>
  <div>
    <el-table :data="tableData">
      <el-table-column label="項目" prop="key"> </el-table-column>
      <el-table-column label="情報" prop="value">
        <template v-slot:default="scope">
          <el-input v-if="editingItem === scope.row.item" v-model="newItem" />
          <span v-else>{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot:default="scope">
          <span v-if="editingItem === scope.row.item">
            <el-button type="primary" v-loading="loading" @click="submit"
              >決定</el-button
            >
            <el-button @click="cancel">キャンセル</el-button>
          </span>
          <el-button
            v-else
            :disabled="scope.row.disable"
            @click="() => clickEdit(scope.row.item, scope.row.value)"
            >編集</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editingItem: '',
      newItem: '',
    };
  },
  computed: {
    tableData() {
      return [
        {
          key: '通貨名',
          value: this.info.name,
          item: 'name',
          disable: false,
        },
        {
          key: '通貨単位',
          value: this.info.unit,
          item: 'unit',
          disable: false,
        },
        {
          key: '地域',
          value: this.info.region,
          item: 'region',
          disable: true,
        },
        // {
        //   key: "カテゴリ",
        //   value: this.info.category,
        // item: 'category'
        //   disable: true
        // },
        {
          key: '通貨説明',
          value: this.info.description,
          item: 'description',
          disable: false,
        },
        {
          key: '登録メールアドレス',
          value: this.info.mail,
          item: 'mail',
          disable: true,
        },
      ];
    },
  },
  methods: {
    submit() {
      switch (this.editingItem) {
        case 'name':
          this.$emit('editName', this.newItem);
          break;
        case 'description':
          this.$emit('editDescription', this.newItem);
          break;
        case 'unit':
          this.$emit('editUnit', this.newItem);
          break;
        case 'region':
        case 'category':
        case 'mail':
        default:
          break;
      }
      this.newItem = '';
      this.editingItem = '';
    },
    clickEdit(item, name) {
      this.editingItem = item;
      this.newItem = name;
    },
    cancel() {
      this.editingItem = '';
      this.newItem = '';
    },
  },
};
</script>

<style></style>
