<template>
  <div class="edit-currency-card-wrapper">
    <p class="edit-currency-title">通貨編集</p>
    <input-form
      index="通貨名"
      :loading="loading.name"
      :value="name"
      @click="$emit('submitName')"
      @cancel="$emit('cancelName')"
      @onInput="e => $emit('inputName', e)"
    />
    <input-form
      index="通貨説明"
      :loading="loading.description"
      :value="description"
      @click="$emit('submitDescription')"
      @cancel="$emit('cancelDescription')"
      @onInput="e => $emit('inputDescription', e)"
    />
  </div>
</template>

<script>
import InputForm from './InputForm';

export default {
  name: 'edit-currency-page',
  components: { InputForm },
  props: {
    loading: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.edit-currency-card-wrapper {
  padding: 0px;
}
.edit-currency-card-wrapper > * {
  padding: 10px 0;
}
.edit-currency-title {
  font-size: 2rem;
  padding: 0;
}
</style>
