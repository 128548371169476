<template>
  <div id="edit-currency-page--wrapper">
    <edit-currency
      :loading="loading"
      :info="info"
      @editName="submitName"
      @editDescription="submitDescription"
      @editUnit="submitUnit"
    />
  </div>
</template>

<script>
import EditCurrency from '../../../components/EditCurrency';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ActionTypes as EditCurrencyActions } from '@/store/modules/editCurrency/actions';
import { ActionTypes as RootActions } from '@/store/actions';

export default {
  name: 'edit-currency-page',
  components: {
    EditCurrency,
  },
  data() {
    return {
      unwatch: null,
    };
  },
  computed: {
    ...mapState('editCurrency', ['isFailed', 'loading']),
    ...mapState({
      info: 'currencyInfo',
    }),
  },
  methods: {
    ...mapActions([RootActions.getCurrencyInfo]),
    ...mapActions('editCurrency', [
      EditCurrencyActions.patchName,
      EditCurrencyActions.patchDescription,
      EditCurrencyActions.patchUnit,
    ]),
    successMessage() {
      this.$message({
        message: '成功しました。',
        type: 'success',
      });
    },
    erroMessage() {
      this.$message.error('失敗しました。');
    },
    async submitName(name) {
      await this.patchName({ name });
      if (this.isFailed) {
        this.erroMessage();
      } else {
        this.successMessage();
      }
    },
    async submitDescription(description) {
      await this.patchDescription({ description });
      if (this.isFailed) {
        this.erroMessage();
      } else {
        this.successMessage();
      }
    },
    async submitUnit(unit) {
      await this.patchUnit({ unit });
      if (this.isFailed) {
        this.erroMessage();
      } else {
        this.successMessage();
      }
    },
  },
};
</script>

<style scoped></style>
